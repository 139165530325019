/**
 * View container.
 * @module components/theme/View/View
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Container, Icon, Grid } from 'semantic-ui-react';
import { Fade } from 'react-reveal';
import { Portal } from 'react-portal';

import { injectIntl } from 'react-intl';

import { Toolbar } from '@plone/volto/components';

import { getContent } from '@plone/volto/actions';
import homeBanner from '../../../public/home_banner.jpg';
import homeBannerMobile from '../../../public/home_banner_414px.jpg';
// import playImg from '../../../public/play.svg';
import NachrichtenSnippet from '../Homepage/Nachrichten';
import SprachmoduleSnippet from '../Homepage/Sprachmodule';
import ThemenweltenSnippet from '../Homepage/Themenwelten';
import Newsletter from '../Homepage/Newsletter';
import Search from '../Homepage/Search';
import AkteurinnenSnippet from '../Homepage/Akteurinnen';
// import ContentSwapper from './ContentSwapper';
// import closeSVG from '../../../public/close.svg';
import FacebookProvider, { Page } from 'react-facebook';
import cookie from 'react-cookie';
import { updateCookieConsent } from '@package/actions';

/**
 * View container class.
 * @class View
 * @extends Component
 */
class HomeView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getContent: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    content: PropTypes.shape({
      layout: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      '@type': PropTypes.string,
    }),
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    content: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContactForm
   */
  constructor(props) {
    super(props);
    this.state = {
      isVideoPlaying: false,
      isClient: false,
    };
  }

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  UNSAFE_componentWillMount() {
    this.props.getContent(this.props.pathname);
    this.setState({ isClient: true });
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.props.getContent(nextProps.pathname);
    }
  }

  toggleVideoDisplay = () =>
    this.setState({ isVideoPlaying: !this.state.isVideoPlaying });

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const enableFacebook = () => {
      let expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      cookie.save('confirm_facebook', 1, {
        path: '/',
        expires: expiryDate,
      });

      this.props.updateCookieConsent({ facebook: true });
    };

    return (
      <div id="HomeView" className="ui container home_page_view">
        {this.props.content && (
          <Helmet
            title="vhs-Ehrenamtsportal: Für Ehrenamtliche in der Flüchtlingsarbeit!"
            meta={[
              {
                name: 'description',
                content:
                  'Mit dem Ehrenamtsportal der vhs bieten wir Ihnen umfangreiche Informationen und Materialien für Ihre ehrenamtliche Flüchtlingsarbeit ▶ Jetzt informieren!',
              },
            ]}
          />
        )}

        <div className="full-width" style={{ marginTop: '0px' }}>
          <img
            key="banner"
            // src={homeBanner}
            alt="banner"
            srcSet={`${homeBanner} 1243w, ${homeBannerMobile} 1242w`}
          />
        </div>

        <div className="main-title" style={{ marginTop: '300px' }}>
          <h1>
            <span className="first-line">
              Das Online-Portal für Ehrenamtliche
            </span>
            <div />
            <span className="second-line">in Grundbildung und Integration</span>
          </h1>
          <Link className="title-link" to="/uber-uns">
            Mehr über uns{' '}
            <Icon name="chevron right" size="small" className="fontawesome" />{' '}
          </Link>
        </div>
        <Fade>
          <ThemenweltenSnippet />
        </Fade>
        <Fade>
          <SprachmoduleSnippet />
        </Fade>
        <Search />
        <AkteurinnenSnippet />
        <Container>
          <div />
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={12} tablet={7} computer={8}>
                <div className="nachrichten">
                  <Fade>
                    <Grid.Row>
                      <NachrichtenSnippet />
                      <div className="nachrichten-archiv">
                        <Link to="/nachrichten">
                          Nachrichtenarchiv{' '}
                          <Icon
                            name="chevron right"
                            size="small"
                            className="fontawesome"
                          />
                        </Link>
                      </div>
                    </Grid.Row>
                  </Fade>
                  <Grid.Row>
                    <Newsletter />
                  </Grid.Row>
                </div>
              </Grid.Column>
              <Grid.Column mobile={12} tablet={5} computer={4}>
                {this.state.isClient && this.props.facebookConsent ? (
                  <div id="facebook-feed">
                    <FacebookProvider appId="407721649976019">
                      <Page
                        href="https://www.facebook.com/ehrenamtsportal"
                        tabs="timeline"
                        adaptContainerWidth={true}
                        height={590}
                      />
                    </FacebookProvider>
                  </div>
                ) : (
                  <div id="facebook-placeholder">
                    <p>
                      Wichtiger Hinweis: Wenn Sie auf diesen Link gehen, werden
                      Inhalte aus folgendem Kanal angezeigt und Verbindungen zu
                      den jeweiligen Serven aufgebaut.
                    </p>

                    <p>
                      Der DVV e.V. macht sich die durch Links erreichbaren
                      Webseiten Dritter nicht zu eigen und ist für deren Inhalt
                      nicht verantwortlich. Der DVV e.V. hat keinen Einfluss
                      darauf, welche personenbezogenen Daten von Ihnen auf
                      dieser Seite verarbeitet werden. Nähere Informationen
                      können Sie hierzu der Datenschutzerklärung des Anbieters
                      der externen Webseite entnehmen.
                    </p>

                    <p>
                      Inhalte des folgenden Kanals werden angezeigt:
                      <br />
                      <a href="https://www.facebook.com/ehrenamtsportal/">
                        facebook.com/ehrenamtsportal
                      </a>
                      <Button
                        className="branded blue"
                        onClick={() => enableFacebook()}
                      >
                        Inhalte von Facebook laden
                      </Button>
                    </p>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
          <Toolbar pathname={this.props.pathname} inner={<span />} />
        </Portal>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      content: state.content.data,
      pathname: props.location.pathname,
      facebookConsent: state.cookieConsent.facebook,
    }),
    {
      getContent,
      updateCookieConsent,
    },
  ),
)(HomeView);
