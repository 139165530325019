/**
 * SprachmodulList view component.
 * @module components/theme/View/Listings/SprachmodulList
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Grid, List } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import ReactGA from 'react-ga';

const messages = defineMessages({
  pdf_file: {
    id: 'PDF file',
    defaultMessage: 'PDF Datei',
  },
  word_file: {
    id: 'Word file',
    defaultMessage: 'Word-Datei',
  },
});

/**
 * SprachmodulList component func.
 * @function SprachmodulList
 * @param {array} items Items object.
 * @returns {string} Markup of the component.
 */
const SprachmodulList = ({ items, intl }) => (
  <Grid stackable centered columns={12} className="sprachmodul-content">
    <Grid.Row>
      <Grid.Column width="6">
        <List className="lernheiten">
          <List.Header content="Lerneinheit" />
          <div className="body">
            {items
              .filter((item) => item['@type'] === 'Lerneinheit')
              .map((item) => (
                <List.Item key={item.url}>
                  <Icon name="users" color="red" circular inverted />
                  <List.Description
                    as="a"
                    href={item.pdf_file ? item.pdf_file.download : null}
                    content={item.title}
                    onClick={() =>
                      ReactGA.event({
                        category: 'Download',
                        action: 'PDF',
                        label: item.pdf_file.download,
                      })
                    }
                  />
                  {item.word_file && (
                    <a
                      href={item.word_file.download}
                      title={`${item.word_file.size} bytes`}
                      onClick={() =>
                        ReactGA.event({
                          category: 'Download',
                          action: 'Word',
                          label: item.word_file.download,
                        })
                      }
                    >
                      <Icon
                        className="fontawesome"
                        name="file word outline"
                        title={intl.formatMessage(messages.word_file)}
                      />
                    </a>
                  )}
                </List.Item>
              ))}
          </div>
        </List>
      </Grid.Column>
      {!isEmpty(
        items.filter((item) => item['@type'] === 'MethodeReference'),
      ) && (
        <Grid.Column width="6">
          <List className="methoden">
            <List.Header content="Alternative Methoden" />
            <div className="body">
              {items
                .filter((item) => item['@type'] === 'MethodeReference')
                .map(
                  (item) =>
                    item.related_methode && (
                      <List.Item key={item.url}>
                        <Icon
                          className="fontawesome"
                          name="book"
                          color="yellow"
                          circular
                          inverted
                        />
                        <List.Description
                          as="a"
                          href={
                            item.related_methode.pdf_file
                              ? item.related_methode.pdf_file.download
                              : null
                          }
                          content={item.title}
                          onClick={() =>
                            ReactGA.event({
                              category: 'Download',
                              action: 'PDF',
                              label: item.related_methode.pdf_file.download,
                            })
                          }
                        />
                        {item.related_methode.word_file && (
                          <a
                            href={item.related_methode.word_file.download}
                            title={`${item.related_methode.word_file.size} bytes`}
                            onClick={() =>
                              ReactGA.event({
                                category: 'Download',
                                action: 'Word',
                                label: item.related_methode.word_file.download,
                              })
                            }
                          >
                            <Icon
                              className="fontawesome"
                              name="file word outline"
                              title={intl.formatMessage(messages.word_file)}
                            />
                          </a>
                        )}
                      </List.Item>
                    ),
                )}
            </div>
          </List>
        </Grid.Column>
      )}
    </Grid.Row>
  </Grid>
);
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SprachmodulList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.object,
      '@type': PropTypes.string,
    }),
  ).isRequired,
};

export default injectIntl(SprachmodulList);
