/**
 * LerneinheitView view component.
 * @module components/theme/View/LerneinheitView
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import { License } from '../../components';

import { injectIntl } from 'react-intl';

/**
 * LerneinheitView class.
 * @class LerneinheitView
 * @extends Component
 */
class LerneinheitView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      lernziel: PropTypes.string,
      themen: PropTypes.string,
      dauer: PropTypes.string,
      sozial_form: PropTypes.string,
      material: PropTypes.string,
      level: PropTypes.string,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
      parent: PropTypes.shape({
        '@id': PropTypes.string,
        title: PropTypes.string,
      }),
      pdf_file: PropTypes.shape({
        'content-type': PropTypes.string,
        filename: PropTypes.string,
        download: PropTypes.string,
        size: PropTypes.number,
      }),
      word_file: PropTypes.shape({
        'content-type': PropTypes.string,
        filename: PropTypes.string,
        download: PropTypes.string,
        size: PropTypes.number,
      }),
    }).isRequired,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div className="view-wrapper ui container">
        <article id="content">
          <header
            className="methode-banner"
            style={{
              backgroundImage: `url(${this.props.content.parent['@id']}/@@images/image)`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left center',
            }}
          >
            <h1 className="kategorie-title">
              {this.props.content.parent.title}
            </h1>
          </header>
          <section id="content-core">
            <h1 className="documentFirstHeading">{this.props.content.title}</h1>
            {this.props.content.description && (
              <p className="description">{this.props.content.description}</p>
            )}
            <div className="methode-fields">
              {this.props.content.themen && (
                <div className="methode-field">
                  <strong>Thema:</strong> {this.props.content.themen}
                </div>
              )}
              {this.props.content.lernziel && (
                <div className="methode-field">
                  <strong>Lernziele:</strong> {this.props.content.lernziel}
                </div>
              )}
              {this.props.content.sozial_form && (
                <div className="methode-field">
                  <strong>Sozialform:</strong>{' '}
                  {(() => {
                    switch (this.props.content.sozial_form.token) {
                      case 'einzeln':
                        return 'Einzeln';
                      case 'partner':
                        return 'Partner';
                      case 'gruppen':
                        return 'Gruppen';
                      default:
                        return 'Einzeln';
                    }
                  })()}
                </div>
              )}
              {this.props.content.dauer && (
                <div className="methode-field">
                  <strong>Dauer:</strong> {this.props.content.dauer}
                </div>
              )}
              {this.props.content.level && (
                <div className="methode-field">
                  <strong>Niveau:</strong>{' '}
                  {(() => {
                    switch (this.props.content.level) {
                      case 'anfanger':
                        return 'Anfänger';
                      case 'fortgeschrittene':
                        return 'Fortgeschrittene';
                      default:
                        return 'Anfänger';
                    }
                  })()}
                </div>
              )}
              {this.props.content.material && (
                <div className="methode-field">
                  <strong>Materialien:</strong> {this.props.content.material}
                </div>
              )}
            </div>
            {this.props.content.text && (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.content.text.data,
                }}
              />
            )}
            <div className="methode-actions">
              {this.props.content.pdf_file && (
                <Button
                  className="branded"
                  primary
                  icon
                  size="large"
                  labelPosition="left"
                  href={this.props.content.pdf_file.download}
                  as="a"
                  onClick={() =>
                    ReactGA.event({
                      category: 'Download',
                      action: 'PDF',
                      label: this.props.content.pdf_file.download,
                    })
                  }
                >
                  <Icon className="fontawesome" name="file pdf outline" />
                  <Button.Content>PDF Download</Button.Content>
                </Button>
              )}
              {this.props.content.word_file && (
                <div className="methode-actions-word">
                  <a
                    href={this.props.content.word_file.download}
                    onClick={() =>
                      ReactGA.event({
                        category: 'Download',
                        action: 'Word',
                        label: this.props.content.word_file.download,
                      })
                    }
                  >
                    Als Word-Dokument downloaden
                  </a>
                </div>
              )}
            </div>
            {this.props.content.license && (
              <License license={this.props.content.license} />
            )}
          </section>
        </article>
      </div>
    );
  }
}

export default injectIntl(LerneinheitView);
