/**
 * Edit container.
 * @module components/manage/Edit/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { Portal } from 'react-portal';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import qs from 'query-string';

import { Form, Icon, Toolbar, Sidebar } from '@plone/volto/components';
import {
  orderContent,
  updateContent,
  getContent,
  getSchema,
} from '@plone/volto/actions';
import { getBaseUrl, hasBlocksData } from '@plone/volto/helpers';

import { RichEdit } from '~/components';
import RichEditContent, { RichEditItem } from '~/constants/RichEditContent';
import AddJobOfferForm from '../../../../components/manage/AddJobOfferForm';

import saveSVG from '@plone/volto/icons/save.svg';
import clearSVG from '@plone/volto/icons/clear.svg';

const messages = defineMessages({
  edit: {
    id: 'Edit {title}',
    defaultMessage: 'Edit {title}',
  },
  save: {
    id: 'Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  add: {
    id: 'Add',
    defaultMessage: 'Add',
  },
});

/**
 * Edit class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    updateContent: PropTypes.func.isRequired,
    getContent: PropTypes.func.isRequired,
    getSchema: PropTypes.func.isRequired,
    updateRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    schemaRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    getRequest: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    returnUrl: PropTypes.string,
    inner: PropTypes.bool,
    content: PropTypes.shape({
      '@type': PropTypes.string,
    }),
    schema: PropTypes.objectOf(PropTypes.any),
  };

  /**
   * Default properties
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    schema: null,
    content: {},
    returnUrl: null,
    inner: false,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs EditComponent
   */
  constructor(props) {
    super(props);
    this.state = {
      visual: true,
    };
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getContent(getBaseUrl(this.props.pathname));
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.pathname !== this.props.pathname ||
      nextProps.inner !== this.props.inner
    ) {
      this.props.getContent(getBaseUrl(nextProps.pathname));
    }
    if (this.props.getRequest.loading && nextProps.getRequest.loaded) {
      this.props.getSchema(nextProps.content?.['@type']);
    }
    if (this.props.schemaRequest.loading && nextProps.schemaRequest.loaded) {
      if (!hasBlocksData(nextProps.schema.properties)) {
        this.setState({
          visual: true,
        });
      }
    }
    // Hack for make the Plone site editable by Volto Editor without checkings
    if (this.props.content && this.props.content?.['@type'] === 'Plone Site') {
      this.setState({
        visual: true,
      });
    }
    if (this.props.updateRequest.loading && nextProps.updateRequest.loaded) {
      this.props.history.push(
        this.props.returnUrl || getBaseUrl(this.props.pathname),
      );
    }
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {object} data Form data.
   * @returns {undefined}
   */
  onSubmit(data) {
    this.props.updateContent(getBaseUrl(this.props.pathname), data);
  }

  /**
   * Cancel handler
   * @method onCancel
   * @returns {undefined}
   */
  onCancel() {
    this.props.history.push(
      this.props.returnUrl || getBaseUrl(this.props.pathname),
    );
  }

  form = React.createRef();

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const type = this.props.content ? this.props.content?.['@type'] : '';
    const isRichPageType = RichEditContent.includes(type);
    const isRichItemType = RichEditItem.includes(type);

    return (
      <div id="page-edit" className="job-offer-edit">
        <Helmet
          title={
            this.props?.schema?.title
              ? this.props.intl.formatMessage(messages.edit, {
                  title: this.props.schema.title,
                })
              : null
          }
        />
        {this.props.schema &&
          (this.props.content?.['@type'] === 'JobOffer' ? (
            <AddJobOfferForm
              ref={this.form}
              schema={this.props.schema}
              formData={this.props.content}
              requestError={this.state.error}
              onSubmit={this.onSubmit}
              hideActions
              isEditForm
              pathname={this.props.pathname}
              visual={this.state.visual}
              title={
                this.props?.schema?.title
                  ? this.props.intl.formatMessage(messages.add, {
                      type: this.props.schema.title,
                    })
                  : null
              }
              loading={this.props.updateRequest.loading}
            />
          ) : RichEditContent.includes(type) && this.props.inner !== true ? (
            <RichEdit
              content={this.props.content}
              orderContent={this.props.orderContent}
              editContent={this.props.editContent}
            />
          ) : (
            <Form
              ref={this.form}
              schema={this.props.schema}
              formData={this.props.content}
              onSubmit={this.onSubmit}
              hideActions
              pathname={this.props.pathname}
              visual={this.state.visual}
              title={
                this.props?.schema?.title
                  ? this.props.intl.formatMessage(messages.edit, {
                      title: this.props.schema.title,
                    })
                  : null
              }
              loading={this.props.updateRequest.loading}
              contenttype={this.props.content?.['@type']}
            />
          ))}
        {this.props.content?.['@type'] !== 'JobOffer' && (
          <Portal node={__CLIENT__ && document.getElementById('toolbar')}>
            <Toolbar
              pathname={this.props.pathname}
              hideDefaultViewButtons
              inner={
                <>
                  {(!RichEditContent.includes(type) ||
                    (RichEditContent.includes(type) &&
                      this.props.inner === true)) && (
                    <Button
                      id="toolbar-save"
                      className="save"
                      aria-label={this.props.intl.formatMessage(messages.save)}
                      onClick={() => this.form.current.onSubmit()}
                      disabled={this.props.updateRequest.loading}
                      loading={this.props.updateRequest.loading}
                    >
                      <Icon
                        name={saveSVG}
                        className="circled"
                        size="30px"
                        title={this.props.intl.formatMessage(messages.save)}
                      />
                    </Button>
                  )}
                  <Button
                    className="cancel"
                    aria-label={this.props.intl.formatMessage(messages.cancel)}
                    onClick={() => this.onCancel()}
                  >
                    <Icon
                      name={clearSVG}
                      className="circled"
                      size="30px"
                      title={this.props.intl.formatMessage(messages.cancel)}
                    />
                  </Button>
                </>
              }
            />
          </Portal>
        )}
        {!isRichPageType && !isRichItemType && this.state.visual && (
          <Portal node={__CLIENT__ && document.getElementById('sidebar')}>
            <Sidebar />
          </Portal>
        )}
      </div>
    );
  }
}

export default compose(
  DragDropContext(HTML5Backend),
  injectIntl,
  connect(
    (state, props) => ({
      content: state.content.data,
      schema: state.schema.schema,
      getRequest: state.content.get,
      schemaRequest: state.schema,
      updateRequest: state.content.update,
      pathname: props.location.pathname,
      returnUrl: qs.parse(props.location.search).return_url,
      inner: qs.parse(props.location.search).inner === 'true',
    }),
    {
      orderContent,
      updateContent,
      getContent,
      getSchema,
    },
  ),
)(Edit);
