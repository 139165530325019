import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import move from 'lodash-move';
import { Link } from 'react-router-dom';
import { Container, Icon, Image } from 'semantic-ui-react';

import { License, RichEditItem } from '../../components';
import { settings } from '../../config';

/**
 * RichEdit class.
 * @class RichEdit
 * @exte
 * RichEdit class.
 * @class RichEdit
 * @extends Component
 */
class RichEdit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    editContent: PropTypes.func.isRequired,
    orderContent: PropTypes.func.isRequired,
    content: PropTypes.shape({
      '@id': PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
      license: PropTypes.string,
      author_image: PropTypes.object,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string,
          author_description: PropTypes.string,
        }),
      ),
    }).isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs RichEdit
   */
  constructor(props) {
    super(props);
    this.moveItem = this.moveItem.bind(this);
    this.state = {
      items: props.content.items,
    };
  }

  /**
   * Move item handler
   * @method moveItem
   * @param {number} dragIndex Drag index.
   * @param {number} hoverIndex Hover index.
   * @returns {undefined}
   */
  moveItem(dragIndex, hoverIndex) {
    this.props.orderContent(
      this.props.content['@id'].replace(settings.apiPath, ''),
      this.state.items[dragIndex]['@id'].replace(/^.*\//, ''),
      hoverIndex - dragIndex,
      map(this.state.items, (item) => item['@id'].replace(/^.*\//, '')),
    );
    this.setState({
      items: move(this.state.items, dragIndex, hoverIndex),
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container>
        <h1 className="documentFirstHeading">
          <Link
            to={`${this.props.content['@id'].replace(settings.apiPath, '')}`}
          >
            <Icon name="arrow left" />
          </Link>
          {this.props.content.title}
          {this.props.content.subtitle && ` – ${this.props.content.subtitle}`}
          <Link
            to={`${this.props.content['@id'].replace(
              settings.apiPath,
              '',
            )}/edit?inner=true&return_url=${this.props.content['@id'].replace(
              settings.apiPath,
              '',
            )}/edit`}
          >
            <Icon name="write" />
          </Link>
        </h1>
        {this.props.content.description && (
          <p className="description">{this.props.content.description}</p>
        )}
        {this.state.items.map((item, index) => (
          <RichEditItem
            {...item}
            key={item['@id']}
            index={index}
            moveItem={this.moveItem}
            previousItem={this.state.items[index - 1]}
          />
        ))}
        <div className="akteur">
          {this.props.content.author_image && (
            <Image
              alt="Autorenbild"
              src={this.props.content.author_image.scales.thumb.download}
            />
          )}
          {this.props.content.text && (
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.content.text.data,
              }}
            />
          )}
        </div>
        {this.props.content.license && (
          <License license={this.props.content.license} />
        )}
      </Container>
    );
  }
}

export default DragDropContext(HTML5Backend)(RichEdit);
