import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { getContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';

const HighlightBody = ({ data, id, isEditMode }) => {
  const contentSubrequests = useSelector((state) => state.content.subrequests);
  const dispatch = useDispatch();
  const result = contentSubrequests?.[id]?.data;

  React.useEffect(() => {
    if (data.href) {
      dispatch(getContent(data.href, null, id));
    }
  }, [dispatch, data, id]);

  return (
    <div>
      {!data.href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>Wählen Sie ein existierenden Inhalt für dieses Element aus.</p>
          </div>
        </Message>
      )}
      {data.href && result && (
        <div className="block-inner-wrapper">
          {!isEditMode ? (
            <Link
              to={flattenToAppURL(data.href)}
              target={data.openLinkInNewTab && '_blank'}
            >
              {result?.preview_image && (
                <img
                  className="teaser-image"
                  src={flattenToAppURL(result.preview_image.download)}
                  alt=""
                  loading="lazy"
                />
              )}
              <div
                className={cx(
                  'text',
                  data?.font_color ? data.font_color : 'white',
                )}
              >
                <h3>{data.title}</h3>
                <div className="description">
                  <p>{data.description}</p>
                </div>
              </div>
            </Link>
          ) : (
            <>
              {result?.preview_image && (
                <img
                  className="teaser-image"
                  src={flattenToAppURL(result.preview_image.download)}
                  alt=""
                  loading="lazy"
                />
              )}
              <div
                className={cx(
                  'text',
                  data?.font_color ? data.font_color : 'white',
                )}
              >
                <h3>{data.title}</h3>
                <div className="description">
                  <p>{data.description}</p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

HighlightBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default injectIntl(HighlightBody);
