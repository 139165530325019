/**
 * RichContentView component.
 * @module components/theme/View/RichContentView
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import cx from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { BarChart, Bar, Cell, XAxis, YAxis } from 'recharts';
import {
  Button,
  Checkbox,
  Container,
  Embed,
  Form,
  Icon,
  Image,
  Item,
  Grid,
  Radio,
} from 'semantic-ui-react';
import filesize from 'filesize';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import {
  lowerCase,
  concat,
  fromPairs,
  isEqual,
  map,
  range,
  without,
} from 'lodash';
import ReactGA from 'react-ga';

import NavigationPortlet from './Portlets/Navigation';
import MediaPortlet from './Portlets/Media';
import licenseSVG from '../../../public/default-license-portlet.svg';
import LightBulb from '../../../theme/globals/bulb.svg';
import Speaker from '../../../theme/globals/speaker.svg';
import commentsSVGWhite from '../../../public/comments-solid-white.svg';
import { FlipCard, License, NextPreviousNav, Youtube } from '../../components';
import { updateContent } from '@plone/volto/actions';
import { settings } from '@plone/volto/config';
import { flattenToAppURL } from '@plone/volto/helpers';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

const messages = defineMessages({
  check: {
    id: 'Check',
    defaultMessage: 'Check',
  },
});

/**
 * RichContent view component class.
 * @class RichContentView
 * @extends Component
 */
class RichContentView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    updateContent: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    items_total: PropTypes.number,
    content: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
      allow_discussion: PropTypes.bool,
      text: PropTypes.shape({
        data: PropTypes.string,
      }),
      author_image: PropTypes.object,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string,
          '@type': PropTypes.string,
          description: PropTypes.string,
          review_state: PropTypes.string,
          title: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }).isRequired,
    token: PropTypes.string,
    tableofcontents_title: PropTypes.string,
    align: PropTypes.string,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
    align: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onSelectSingle = this.onSelectSingle.bind(this);
    this.onSelectMultiple = this.onSelectMultiple.bind(this);
    this.onSelectMultipleSurvey = this.onSelectMultipleSurvey.bind(this);
    this.onSelectSingleSurvey = this.onSelectSingleSurvey.bind(this);
    this.onCheckMultiple = this.onCheckMultiple.bind(this);
    this.onCheckMultipleSurvey = this.onCheckMultipleSurvey.bind(this);
    this.state = {
      singleQuestions: {},
      multipleQuestions: {},
      surveys:
        JSON.parse(
          typeof localStorage !== 'undefined' &&
            localStorage.getItem('surveys'),
        ) || {},
      multipleSurveyAnswers: {},
      multipleResult: {},
    };
  }

  /**
   * On select single
   * @method onSelectSingle
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onSelectSingle(event, { value }) {
    this.setState({
      singleQuestions: {
        ...this.state.singleQuestions,
        [value.id]: value.index,
      },
    });
  }

  /**
   * On select multiple
   * @method onSelectMultiple
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onSelectMultiple(event, { value }) {
    const currentValue = this.state.multipleQuestions[value.id] || [];

    this.setState({
      multipleQuestions: {
        ...this.state.multipleQuestions,
        [value.id]:
          currentValue.indexOf(value.value) === -1
            ? concat(currentValue, value.value)
            : without(currentValue, value.value),
      },
    });
  }

  /**
   * On select single survey
   * @method onSelectSingleSurvey
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onSelectSingleSurvey(event, { value }) {
    this.props.updateContent(value.id.replace(settings.apiPath, ''), {
      survey_user_answer: value.value,
    });
    this.setState(
      {
        surveys: {
          ...this.state.surveys,
          [value.id]: [value.value],
        },
      },
      () => {
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('surveys', JSON.stringify(this.state.surveys));
        }
      },
    );
  }

  /**
   * On select multiple survey
   * @method onSelectMultipleSurvey
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onSelectMultipleSurvey(event, { value }) {
    const currentValue = this.state.multipleSurveyAnswers[value.id] || [];

    this.setState({
      multipleSurveyAnswers: {
        ...this.state.multipleSurveyAnswers,
        [value.id]:
          currentValue.indexOf(value.value) === -1
            ? concat(currentValue, value.value)
            : without(currentValue, value.value),
      },
    });
  }

  /**
   * On check multiple
   * @method onCheckMultiple
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onCheckMultiple(event, { value }) {
    const currentValue = this.state.multipleQuestions[value.id] || [];
    const originalAnswersValue = [...value.answers];

    this.setState({
      multipleResult: {
        ...this.state.multipleResult,
        [value.id]: isEqual(currentValue.sort(), value.answers.sort())
          ? ['Richtig!', true]
          : [
              `Die richtigen Antworten wären gewesen: ${originalAnswersValue.join(
                ', ',
              )}.`,
              false,
            ],
      },
    });
  }

  /**
   * On check multiple survey
   * @method onCheckMultipleSurvey
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onCheckMultipleSurvey(event, { value }) {
    const answers = this.state.multipleSurveyAnswers[value.id];
    this.props.updateContent(value['@id'].replace(settings.apiPath, ''), {
      survey_user_answer: answers,
    });
    this.setState(
      {
        surveys: {
          ...this.state.surveys,
          [value['@id']]: answers,
        },
      },
      () => {
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('surveys', JSON.stringify(this.state.surveys));
        }
      },
    );
  }

  getTOC = (items) => {
    // We determine if the TOC should appear or not (if there's any item.title)
    if (!!items.reduce((acc, item) => acc || item.title, false)) {
      return (
        <div className="table-of-contents">
          {this.props.content.tableofcontents_title ? (
            <h2
              style={
                this.props.content['@type'] !== 'Lektion'
                  ? { marginTop: 0 }
                  : null
              }
            >
              {this.props.content.tableofcontents_title}
            </h2>
          ) : (
            <h2
              style={
                this.props.content['@type'] !== 'Lektion'
                  ? { marginTop: 0 }
                  : null
              }
            >
              In dieser Lektion
            </h2>
          )}
          <ul>
            {items.map((item) =>
              item.title ? (
                <li>
                  <AnchorLink offset="180px" href={`#${item.id}`}>
                    {item.title}
                  </AnchorLink>
                </li>
              ) : null,
            )}
          </ul>
        </div>
      );
    }
    return <div />;
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Container>
        <div id="page-module-view">
          <section id="content-core">
            <div
              className={cx('content-header', {
                withComments: this.props.allow_discussion,
              })}
            >
              <h1 className="documentFirstHeading">
                {this.props.content.title}
                {this.props.content.subtitle &&
                  ` – ${this.props.content.subtitle}`}
              </h1>
              {this.props.content.allow_discussion && (
                <AnchorLink
                  offset="78px"
                  href="#comments"
                  className="ui green icon right labeled button comment"
                >
                  Zu den Kommentaren
                  <i className="icon">
                    <img src={commentsSVGWhite} alt="commentsSVG" />
                    {this.props.items_total >= 1 && (
                      <span>{this.props.items_total}</span>
                    )}
                  </i>
                </AnchorLink>
              )}
            </div>
            {this.props.content.description && (
              <p className="description">{this.props.content.description}</p>
            )}
            <Grid stackable className="centered-tablet">
              <Grid.Column mobile={12} tablet={12} computer={8}>
                {this.getTOC(this.props.content.items)}
                {this.props.content.items.map((item) => (
                  <div
                    key={item.url}
                    className={`block ${item['@type'].toLowerCase()} ${
                      item.align ? lowerCase(item.align.token) : ''
                    }`}
                  >
                    {item.title && item['@type'] !== 'File' && (
                      <h2 id={item.id}>{item.title}</h2>
                    )}
                    {item['@type'] === 'TurningCard' && (
                      <Item.Group link>
                        {map(
                          range(1, 11),
                          (i) =>
                            (item[`front_image${i}`] ||
                              (item[`front_text${i}`] &&
                                item[`front_text${i}`].data !==
                                  '<p><br/></p>')) &&
                            (item[`back_image${i}`] ||
                              (item[`back_text${i}`] &&
                                item[`back_text${i}`].data !==
                                  '<p><br/></p>')) && (
                              <Item key={i}>
                                <FlipCard
                                  frontText={
                                    item[`front_text${i}`]
                                      ? item[`front_text${i}`].data
                                      : ''
                                  }
                                  frontImage={
                                    item[`front_image${i}`]
                                      ? item[`front_image${i}`].scales.large
                                          .download
                                      : null
                                  }
                                  frontColorClass={
                                    item[`front_color_class${i}`]
                                  }
                                  backText={
                                    item[`back_text${i}`]
                                      ? item[`back_text${i}`].data
                                      : ''
                                  }
                                  backImage={
                                    item[`back_image${i}`]
                                      ? item[`back_image${i}`].scales.large
                                          .download
                                      : null
                                  }
                                  backColorClass={item[`back_color_class${i}`]}
                                  icon={
                                    <Icon
                                      name="refresh"
                                      className="fontawesome"
                                      circular
                                      inverted
                                    />
                                  }
                                />
                              </Item>
                            ),
                        )}
                      </Item.Group>
                    )}
                    {item['@type'] === 'Video' && (
                      <div>
                        {item.description && <p>{item.description}</p>}
                        <Youtube
                          url={item.youtube_embed_url}
                          autoplay={false}
                          previewImage={item.youtube_image}
                        />
                        <div>
                          {item.transcript_description && (
                            <p style={{ marginTop: '10px' }}>
                              {item.transcript_description}
                            </p>
                          )}
                          {item.transcript_file && (
                            <Fragment>
                              <a
                                href={item.transcript_file.download}
                                onClick={() =>
                                  ReactGA.event({
                                    category: 'Download',
                                    action: 'Transcript',
                                    label: item.transcript_file.download,
                                  })
                                }
                              >
                                {item.transcript_title
                                  ? item.transcript_title
                                  : item.transcript_file.filename}
                              </a>{' '}
                              - {filesize(item.transcript_file.size)}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    )}
                    {item['@type'] === 'RichPageImage' && item.image && (
                      <div>
                        {item?.image_link ? (
                          <UniversalLink
                            href={flattenToAppURL(item.image_link)}
                            target={item?.blank_link && '_blank'}
                            rel={item?.blank_link && 'noopener noreferrer'}
                          >
                            <Image
                              alt={item.title}
                              src={item.image.scales.large.download}
                            />
                          </UniversalLink>
                        ) : (
                          <Image
                            alt={item.title}
                            src={item.image.scales.large.download}
                          />
                        )}
                        {item.image_caption && <p>{item.image_caption}</p>}
                      </div>
                    )}
                    {item['@type'] === 'RichPageText' && item.text && (
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: item.text.data }}
                      />
                    )}
                    {item['@type'] === 'Audio' && item.file && (
                      <div>
                        {item.image && (
                          <Image
                            alt={
                              item.image_caption
                                ? item.image_caption
                                : item.title
                            }
                            src={item.image.scales.large.download}
                          />
                        )}
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <audio controls>
                          <source src={item.file.download} />
                        </audio>
                      </div>
                    )}
                    {item['@type'] === 'GoogleMap' && (
                      <Embed url={item.google_map_embed_url} defaultActive />
                    )}
                    {item['@type'] === 'RichPageFile' && item.file && (
                      <div>
                        {item.description && <p>{item.description}</p>}
                        <a
                          href={item.file.download}
                          onClick={() =>
                            ReactGA.event({
                              category: 'Download',
                              action: 'GenericFile',
                              label: item.file.download,
                            })
                          }
                        >
                          {item.alternate_filename
                            ? item.alternate_filename
                            : item.title || item.file.filename}
                        </a>{' '}
                        - {filesize(item.file.size)}
                      </div>
                    )}
                    {item['@type'] === 'Link' && (
                      <Link to={item.remoteUrl}>{item.title}</Link>
                    )}
                    {item['@type'] === 'Choice' && (
                      <Form>
                        <fieldset>
                          <legend>{item.question}</legend>
                          {map(
                            [1, 2, 3, 4, 5],
                            (i) =>
                              item[`answer${i}`] && (
                                <Form.Field key={i}>
                                  <Radio
                                    label={item[`answer${i}`]}
                                    name={item.id}
                                    value={{
                                      id: item.id,
                                      index: i,
                                    }}
                                    checked={
                                      this.state.singleQuestions[item.id] === i
                                    }
                                    onChange={this.onSelectSingle}
                                  />
                                </Form.Field>
                              ),
                          )}
                          {map(
                            [1, 2, 3, 4, 5],
                            (i) =>
                              this.state.singleQuestions[item.id] === i && (
                                <div
                                  style={{
                                    color: item[`right_answer${i}`]
                                      ? '#53A63B'
                                      : '#8C0C25',
                                  }}
                                  key={i}
                                >
                                  {item[`right_answer${i}`] ? '' : ''}{' '}
                                  {item[`feedback${i}`]}
                                </div>
                              ),
                          )}
                        </fieldset>
                      </Form>
                    )}
                    {item['@type'] === 'Survey' &&
                      (() => {
                        const SurveyForm = () => (
                          <Form>
                            <fieldset>
                              <legend>{item.question}</legend>
                              {item.allow_multianswer
                                ? !!item.choices_values &&
                                  concat(
                                    map(
                                      item.choices_values.split('\n'),
                                      (value) => (
                                        <Form.Field key={value}>
                                          <Checkbox
                                            label={value}
                                            disabled={!this.props.token}
                                            checked={
                                              this.state.multipleSurveyAnswers[
                                                item.id
                                              ] &&
                                              this.state.multipleSurveyAnswers[
                                                item.id
                                              ].indexOf(value) !== -1
                                            }
                                            value={{
                                              id: item.id,
                                              value,
                                            }}
                                            onChange={
                                              this.onSelectMultipleSurvey
                                            }
                                          />
                                        </Form.Field>
                                      ),
                                    ),
                                    [
                                      <Form.Field>
                                        <Button
                                          className="blue left"
                                          value={{
                                            id: item.id,
                                            '@id': item['@id'],
                                          }}
                                          onClick={this.onCheckMultipleSurvey}
                                        >
                                          {this.props.intl.formatMessage(
                                            messages.check,
                                          )}
                                        </Button>
                                      </Form.Field>,
                                    ],
                                  )
                                : !!item.choices_values &&
                                  map(
                                    item.choices_values.split('\n'),
                                    (value) => (
                                      <Form.Field key={value}>
                                        <Radio
                                          disabled={!this.props.token}
                                          label={value}
                                          name={value}
                                          value={{
                                            id: item['@id'],
                                            value,
                                          }}
                                          onChange={this.onSelectSingleSurvey}
                                        />
                                      </Form.Field>
                                    ),
                                  )}
                            </fieldset>
                          </Form>
                        );
                        if (!this.props.token) {
                          return (
                            <Fragment>
                              <SurveyForm />
                              <Button
                                style={{
                                  marginTop: '10px',
                                  textTransform: 'initial',
                                }}
                                as={Link}
                                to="/login"
                              >
                                Melden Sie sich an, um an der Umfrage
                                teilzunehmen.
                              </Button>
                            </Fragment>
                          );
                        }
                        if (this.state.surveys[item['@id']]) {
                          const usersChoices = this.state.surveys[item['@id']];
                          const itemChoices = item.choices_values.split('\n');
                          return (
                            <div>
                              <h2 className="question">{item.question}</h2>
                              <BarChart
                                width={742}
                                height={61 * itemChoices.length + 55}
                                layout="vertical"
                                margin={{
                                  top: 20,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                                data={map(itemChoices, (val, index) => ({
                                  label: val,
                                  data:
                                    fromPairs(item.survey_results)[val] || 0,
                                }))}
                              >
                                <XAxis
                                  dataKey="data"
                                  type="number"
                                  interval={1}
                                />
                                <YAxis
                                  dataKey="label"
                                  interval={0}
                                  type="category"
                                  width={150}
                                />
                                <Bar
                                  dataKey="data"
                                  label={{ fill: '#666', position: 'right' }}
                                  barGap={0}
                                >
                                  {map(itemChoices, (entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={
                                        usersChoices.indexOf(entry) !== -1
                                          ? '#64B9E6'
                                          : '#00285A'
                                      }
                                    />
                                  ))}
                                </Bar>
                              </BarChart>
                            </div>
                          );
                        }
                        return <SurveyForm />;
                      })()}
                    {item['@type'] === 'MultiChoice' && (
                      <Form>
                        <fieldset>
                          <legend>{item.question}</legend>
                          {item.choices_values &&
                            map(item.choices_values.split('\n'), (value) => (
                              <Form.Field key={value}>
                                <Checkbox
                                  label={value}
                                  checked={
                                    this.state.multipleQuestions[item.id] &&
                                    this.state.multipleQuestions[
                                      item.id
                                    ].indexOf(value) !== -1
                                  }
                                  value={{
                                    id: item.id,
                                    value,
                                  }}
                                  onChange={this.onSelectMultiple}
                                />
                              </Form.Field>
                            ))}
                          <Form.Field>
                            <Button
                              className="blue left"
                              value={{
                                id: item.id,
                                answers: item.correct_choice
                                  ? item.correct_choice.split('\n')
                                  : [],
                              }}
                              onClick={this.onCheckMultiple}
                            >
                              {this.props.intl.formatMessage(messages.check)}
                            </Button>
                            {this.state.multipleResult[item.id] && (
                              <div
                                style={{
                                  color: this.state.multipleResult[item.id][1]
                                    ? '#53A63B'
                                    : '#8C0C25',
                                  display: 'inline',
                                  fontSize: '1rem',
                                  marginLeft: '20px',
                                }}
                              >
                                {this.state.multipleResult[item.id][0]}
                              </div>
                            )}
                          </Form.Field>
                        </fieldset>
                      </Form>
                    )}
                    {item['@type'] === 'Slideshow' && (
                      <Slider dots>
                        {map(
                          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                          (i) =>
                            item[`slide${i}_image`] && (
                              <div key={i}>
                                <Image
                                  alt={item.title}
                                  src={
                                    item[`slide${i}_image`].scales.large
                                      .download
                                  }
                                />
                                {item[`slide${i}_text`] && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item[`slide${i}_text`].data,
                                    }}
                                  />
                                )}
                              </div>
                            ),
                        )}
                      </Slider>
                    )}
                    {item['@type'] === 'MerkkastenBulb' && item.text && (
                      <div className="merkkasten bulb">
                        <img src={LightBulb} alt=""></img>
                        <div
                          className="merkkasten-content"
                          dangerouslySetInnerHTML={{ __html: item.text.data }}
                        ></div>
                      </div>
                    )}
                    {item['@type'] === 'MerkkastenQuote' && item.text && (
                      <div className="merkkasten quote">
                        <div
                          dangerouslySetInnerHTML={{ __html: item.text.data }}
                        />
                      </div>
                    )}
                    {item['@type'] === 'MerkkastenHighlight' && item.text && (
                      <div
                        className="merkkasten highlight"
                        dangerouslySetInnerHTML={{ __html: item.text.data }}
                      />
                    )}
                    {item['@type'] === 'MerkkastenSpeaker' && item.text && (
                      <div className="merkkasten speaker">
                        <img src={Speaker} alt=""></img>
                        <div className="merkkasten-content">
                          <div
                            dangerouslySetInnerHTML={{ __html: item.text.data }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="akteur">
                  {this.props.content.author_image && (
                    <Image
                      alt="Autorenbild"
                      src={
                        this.props.content.author_image.scales.thumb.download
                      }
                    />
                  )}
                  {this.props.content.text && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.content.text.data,
                      }}
                    />
                  )}
                </div>
                {this.props.content.license && (
                  <License license={this.props.content.license} />
                )}
                <NextPreviousNav content={this.props.content} />
              </Grid.Column>
              <Grid.Column
                mobile={12}
                tablet={6}
                computer={4}
                className="portlets-column"
                style={
                  this.props.content['@type'] !== 'Lektion'
                    ? { paddingTop: 'calc(28px + 24px + 14px)' }
                    : null
                }
              >
                {this.props.content['@type'] === 'Lektion' && (
                  <NavigationPortlet content={this.props.content} />
                )}
                {this.props.content.portlet_image_1 && (
                  <aside className="image-portlet">
                    <a
                      href={this.props.content.portlet_link_1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={
                          this.props.content.portlet_image_1.scales.preview
                            .download
                        }
                      />
                    </a>
                  </aside>
                )}
                {this.props.content.portlet_show_license && (
                  <aside className="license-portlet">
                    <Image src={licenseSVG} />
                    <div className="inner">
                      <p>
                        Die Inhalte im vhs-Ehrenamtsportal unterliegen ― sofern
                        nicht anders gekennzeichnet ― der Lizenz{' '}
                        <strong>CC BY SA 4.0.</strong>
                      </p>
                      <Link to="/oer">
                        Mehr erfahren{' '}
                        <Icon
                          name="chevron right"
                          size="small"
                          className="fontawesome"
                        />
                      </Link>
                    </div>
                  </aside>
                )}
                <MediaPortlet content={this.props.content} />
              </Grid.Column>
            </Grid>
          </section>
        </div>
      </Container>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      token: state.userSession.token,
      items_total: state.comments.items_total,
    }),
    {
      updateContent,
    },
  ),
)(RichContentView);
