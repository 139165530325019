import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { searchContent } from '@plone/volto/actions';
import { settings } from '@plone/volto/config';

/**
 * NavigationPortlet view component class.
 * @class NavigationPortlet
 * @extends Component
 */
class NavigationPortlet extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContent: PropTypes.func.isRequired,
    content: PropTypes.shape({
      id: PropTypes.string,
      parent: PropTypes.shape({
        '@id': PropTypes.string,
      }),
      '@type': PropTypes.string,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        navigation_title: PropTypes.string,
      }),
    ).isRequired,
    mobile: PropTypes.bool,
  };

  static defaultProps = {
    mobile: false,
  };

  state = {};

  /**
   * Component will mount
   * @method componentWillMount
   * @returns {undefined}
   */
  componentWillMount() {
    const path = this.props.content.parent['@id'].replace(settings.apiPath, '');

    if (path !== '') {
      this.props.searchContent(path, {
        portal_type:
          this.props.content['@type'] === 'Lektion' ? 'Lektion' : 'Sprachmodul',
        fullobjects: 1,
        sort_on: 'getObjPositionInParent',
        depth: 1,
      });
    }
  }

  /**
   * Render
   * @returns {string} Markup
   * @memberof AkteureView
   */
  render() {
    const activeItem = this.props.content.id;

    const navigationItems = this.props.items.filter((item) => {
      return Boolean(item.navigation_title);
    });
    if (!navigationItems.length) {
      return <div />;
    }
    return (
      <aside
        className={cx('navigation-portlet', { mobile: this.props.mobile })}
      >
        <h2>Weitere Lektionen</h2>
        <Menu compact borderless stackable vertical>
          {map(navigationItems, (item) => (
            <Menu.Item
              key={item.id}
              name={item.id}
              active={activeItem === item.id}
              onClick={this.handleItemClick}
              as={Link}
              to={item['@id']}
            >
              {item.navigation_title}
            </Menu.Item>
          ))}
        </Menu>
      </aside>
    );
  }
}

export default connect(
  (state) => ({
    items: state.search.items,
  }),
  (dispatch) => bindActionCreators({ searchContent }, dispatch),
)(NavigationPortlet);
