/**
 * Rich edit item component.
 * @module components/manage/Edit/RichEditItem
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import {
  Button,
  Checkbox,
  Form,
  Radio,
  Embed,
  Icon,
  Image,
  Item,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import filesize from 'filesize';
import { concat, isEqual, lowerCase, map, range, without } from 'lodash';
import Slider from 'react-slick';
import { defineMessages, injectIntl } from 'react-intl';

import { settings } from '../../config';
import { FlipCard, Youtube } from '../../components';
import LightBulb from '../../../theme/globals/bulb.svg';
import Speaker from '../../../theme/globals/speaker.svg';

const messages = defineMessages({
  check: {
    id: 'Check',
    defaultMessage: 'Check',
  },
});

const itemSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const ItemTypes = {
  ITEM: 'item',
};

const itemTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveItem(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

/**
 * RichEditItem class.
 * @class RichEditItem
 * @extends Component
 */
class RichEditItem extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    '@type': PropTypes.string.isRequired,
    image: PropTypes.object,
    moveItem: PropTypes.func.isRequired,
    align: PropTypes.string,
    previousItem: PropTypes.object,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    align: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onSelectSingle = this.onSelectSingle.bind(this);
    this.onSelectMultiple = this.onSelectMultiple.bind(this);
    this.onCheckMultiple = this.onCheckMultiple.bind(this);
    this.state = {
      singleQuestions: {},
      multipleQuestions: {},
      multipleResult: {},
      focus: '',
    };
  }

  /**
   * On select single
   * @method onSelectSingle
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onSelectSingle(event, { value }) {
    this.setState({
      singleQuestions: {
        ...this.state.singleQuestions,
        [value.id]: value.index,
      },
    });
  }

  /**
   * On select multiple
   * @method onSelectMultiple
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onSelectMultiple(event, { value }) {
    const currentValue = this.state.multipleQuestions[value.id] || [];

    this.setState({
      multipleQuestions: {
        ...this.state.multipleQuestions,
        [value.id]:
          currentValue.indexOf(value.value) === -1
            ? concat(currentValue, value.value)
            : without(currentValue, value.value),
      },
    });
  }

  /**
   * On check multiple
   * @method onCheckMultiple
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onCheckMultiple(event, { value }) {
    const currentValue = this.state.multipleQuestions[value.id] || [];
    const originalAnswersValue = [...value.answers];

    this.setState({
      multipleResult: {
        ...this.state.multipleResult,
        [value.id]: isEqual(currentValue.sort(), value.answers.sort())
          ? ['Richtig!', true]
          : [
              `Die richtigen Antworten wären gewesen: ${originalAnswersValue.join(
                ', ',
              )}.`,
              false,
            ],
      },
    });
  }

  onClickFocus(block) {
    this.setState({ focus: block?.UID });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  render() {
    const {
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
    } = this.props;

    return connectDropTarget(
      connectDragPreview(
        <div
          className={`drag layer block ${this.props['@type'].toLowerCase()} ${
            this.props.align ? lowerCase(this.props.align.token) : ''
          }`}
          onClick={() => this.onClickFocus(this.props)}
          onKeyDown={() => this.onClickFocus(this.props)}
          tabIndex={0}
          role="button"
        >
          <div
            className="drag toolbar-handle"
            style={{
              marginTop: this.props.title ? '2.57142857em' : null,
              top: this.props.title ? '30px' : null,
            }}
          >
            {connectDragSource(
              <div>
                <Icon className="drag handle" name="content" size="large" />
              </div>,
            )}
          </div>
          <div
            className="drag edit"
            style={{
              marginTop: this.props.title ? '2.57142857em' : null,
              top: this.props.title ? '30px' : null,
              right:
                this.props?.['@type'] === 'RichPageText' &&
                this.props.previousItem &&
                this.props.previousItem?.['@type'] === 'RichPageImage' &&
                this.props.previousItem?.align?.token === 'right' &&
                '47%',
              display: this.props?.UID === this.state.focus && 'block',
              zIndex: '2',
            }}
          >
            <Link
              to={`${this.props.url}/edit?return_url=${this.props.parent[
                '@id'
              ].replace(settings.apiPath, '')}/edit`}
            >
              <Icon name="write" size="large" />
            </Link>
            <br />
            <Link
              to={`${this.props.url}/delete?return_url=${this.props.parent[
                '@id'
              ].replace(settings.apiPath, '')}/edit`}
            >
              <Icon name="trash" size="large" />
            </Link>
          </div>
          {this.props.title && this.props['@type'] !== 'File' && (
            <h2>{this.props.title}</h2>
          )}
          {this.props['@type'] === 'TurningCard' && (
            <Item.Group link>
              {map(
                range(1, 11),
                (i) =>
                  (this.props[`front_image${i}`] ||
                    (this.props[`front_text${i}`] &&
                      this.props[`front_text${i}`].data !== '<p><br/></p>')) &&
                  (this.props[`back_image${i}`] ||
                    (this.props[`back_text${i}`] &&
                      this.props[`back_text${i}`].data !== '<p><br/></p>')) && (
                    <Item key={i}>
                      <FlipCard
                        frontText={
                          this.props[`front_text${i}`]
                            ? this.props[`front_text${i}`].data
                            : ''
                        }
                        frontImage={
                          this.props[`front_image${i}`]
                            ? this.props[`front_image${i}`].scales.large
                                .download
                            : null
                        }
                        frontColorClass={
                          this.props[`front_color_class${i}`]
                            ? this.props[`front_color_class${i}`]
                            : null
                        }
                        backText={
                          this.props[`back_text${i}`]
                            ? this.props[`back_text${i}`].data
                            : ''
                        }
                        backImage={
                          this.props[`back_image${i}`]
                            ? this.props[`back_image${i}`].scales.large.download
                            : null
                        }
                        backColorClass={
                          this.props[`back_color_class${i}`]
                            ? this.props[`back_color_class${i}`]
                            : null
                        }
                        icon={
                          <Icon
                            name="refresh"
                            className="fontawesome"
                            circular
                            inverted
                          />
                        }
                      />
                    </Item>
                  ),
              )}
            </Item.Group>
          )}
          {this.props['@type'] === 'Video' && (
            <div>
              {this.props.description && <p>{this.props.description}</p>}
              <Youtube
                url={this.props.youtube_embed_url}
                autoplay={false}
                previewImage={this.props.youtube_image}
              />
              <div>
                {this.props.transcript_description && (
                  <p style={{ marginTop: '10px' }}>
                    {this.props.transcript_description}
                  </p>
                )}
                {this.props.transcript_file && (
                  <Fragment>
                    <a href={this.props.transcript_file.download}>
                      {this.props.transcript_title
                        ? this.props.transcript_title
                        : this.props.transcript_file.filename}
                    </a>{' '}
                    - {filesize(this.props.transcript_file.size)}
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {this.props['@type'] === 'RichPageImage' && (
            <div>
              {this.props.image ? (
                <Image
                  alt={this.props.title}
                  src={this.props.image.scales.large.download}
                />
              ) : (
                <p>
                  <b>Fehlendes Bild</b>
                </p>
              )}
              {this.props.image_caption && <p>{this.props.image_caption}</p>}
            </div>
          )}
          {this.props['@type'] === 'RichPageText' && this.props.text && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: this.props.text.data }}
            />
          )}
          {this.props['@type'] === 'Audio' && this.props.file && (
            <div>
              {this.props.image && (
                <Image
                  alt={
                    this.props.image_caption
                      ? this.props.image_caption
                      : this.props.title
                  }
                  src={this.props.image.scales.large.download}
                />
              )}
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <audio controls>
                <source src={this.props.file.download} />
              </audio>
            </div>
          )}
          {this.props['@type'] === 'GoogleMap' && (
            <Embed url={this.props.google_map_embed_url} defaultActive />
          )}
          {this.props['@type'] === 'RichPageFile' && this.props.file && (
            <div>
              {this.props.description && <p>{this.props.description}</p>}
              <a href={this.props.file.download}>
                {this.props.alternate_filename
                  ? this.props.alternate_filename
                  : this.props.title || this.props.file.filename}
              </a>{' '}
              - {filesize(this.props.file.size)}
            </div>
          )}
          {this.props['@type'] === 'Link' && (
            <Link to={this.props.remoteUrl}>{this.props.title}</Link>
          )}
          {this.props['@type'] === 'Choice' && (
            <Form>
              <fieldset>
                <legend>{this.props.question}</legend>
                {map(
                  [1, 2, 3, 4, 5],
                  (i) =>
                    this.props[`answer${i}`] && (
                      <Form.Field key={this.props.id}>
                        <Radio
                          label={this.props[`answer${i}`]}
                          name={this.props.id}
                          value={{
                            id: this.props.id,
                            index: i,
                          }}
                          checked={
                            this.state.singleQuestions[this.props.id] === i
                          }
                          onChange={this.onSelectSingle}
                        />
                      </Form.Field>
                    ),
                )}
                {map(
                  [1, 2, 3, 4, 5],
                  (i) =>
                    this.state.singleQuestions[this.props.id] === i && (
                      <div
                        style={{
                          color: this.props[`right_answer${i}`]
                            ? '#53A63B'
                            : '#8C0C25',
                        }}
                        key={i}
                      >
                        {this.props[`right_answer${i}`] ? '' : ''}{' '}
                        {this.props[`feedback${i}`]}
                      </div>
                    ),
                )}
              </fieldset>
            </Form>
          )}
          {this.props['@type'] === 'MultiChoice' && (
            <Form>
              <fieldset>
                <legend>{this.props.question}</legend>
                {this.props.choices_values &&
                  map(this.props.choices_values.split('\n'), (value) => (
                    <Form.Field key={value}>
                      <Checkbox
                        label={value}
                        checked={
                          this.state.multipleQuestions[this.props.id] &&
                          this.state.multipleQuestions[this.props.id].indexOf(
                            value,
                          ) !== -1
                        }
                        value={{
                          id: this.props.id,
                          value,
                        }}
                        onChange={this.onSelectMultiple}
                      />
                    </Form.Field>
                  ))}
                <Form.Field>
                  <Button
                    className="blue left"
                    value={{
                      id: this.props.id,
                      answers: this.props.correct_choice
                        ? this.props.correct_choice.split('\n')
                        : [],
                    }}
                    onClick={this.onCheckMultiple}
                  >
                    {this.props.intl.formatMessage(messages.check)}
                  </Button>
                  {this.state.multipleResult[this.props.id] && (
                    <div
                      style={{
                        color: this.state.multipleResult[this.props.id][1]
                          ? '#53A63B'
                          : '#8C0C25',
                        display: 'inline',
                        fontSize: '1rem',
                        marginLeft: '20px',
                      }}
                    >
                      {this.state.multipleResult[this.props.id][0]}
                    </div>
                  )}
                </Form.Field>
              </fieldset>
            </Form>
          )}
          {this.props['@type'] === 'Survey' && (
            <Form>
              <fieldset>
                <legend>{this.props.question}</legend>
                {this.props.allow_multianswer
                  ? !!this.props.choices_values &&
                    concat(
                      map(this.props.choices_values.split('\n'), (value) => (
                        <Form.Field key={value}>
                          <Checkbox label={value} checked={false} />
                        </Form.Field>
                      )),
                      [
                        <Form.Field>
                          <Button
                            className="blue left"
                            value={{
                              id: this.props.id,
                              answers: this.props.correct_choice
                                ? this.props.correct_choice.split('\n')
                                : [],
                            }}
                            onClick={this.onCheckMultiple}
                          >
                            {this.props.intl.formatMessage(messages.check)}
                          </Button>
                        </Form.Field>,
                      ],
                    )
                  : !!this.props.choices_values &&
                    map(this.props.choices_values.split('\n'), (value) => (
                      <Form.Field key={value}>
                        <Radio label={value} name={value} checked={false} />
                      </Form.Field>
                    ))}
              </fieldset>
            </Form>
          )}
          {this.props['@type'] === 'Slideshow' && (
            <Slider dots>
              {map(
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                (i) =>
                  this.props[`slide${i}_image`] && (
                    <div key={i}>
                      <Image
                        alt={this.props.title}
                        src={
                          this.props[`slide${i}_image`].scales.large.download
                        }
                      />
                      {this.props[`slide${i}_text`] && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.props[`slide${i}_text`].data,
                          }}
                        />
                      )}
                    </div>
                  ),
              )}
            </Slider>
          )}
          {this.props['@type'] === 'MerkkastenBulb' && (
            <div className="merkkasten bulb">
              <img src={LightBulb} alt=""></img>
              <div
                className="merkkasten-content"
                dangerouslySetInnerHTML={{ __html: this.props.text.data }}
              ></div>
            </div>
          )}
          {this.props['@type'] === 'MerkkastenQuote' && (
            <div className="merkkasten quote">
              <div dangerouslySetInnerHTML={{ __html: this.props.text.data }} />
            </div>
          )}
          {this.props['@type'] === 'MerkkastenHighlight' && (
            <div
              className="merkkasten highlight"
              dangerouslySetInnerHTML={{ __html: this.props.text.data }}
            />
          )}
          {this.props['@type'] === 'MerkkastenSpeaker' && (
            <div className="merkkasten speaker">
              <img src={Speaker} alt=""></img>
              <div className="merkkasten-content">
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.text.data }}
                />
              </div>
            </div>
          )}
        </div>,
      ),
    );
  }
}

export default compose(
  injectIntl,
  DropTarget(ItemTypes.ITEM, itemTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })),
  DragSource(ItemTypes.ITEM, itemSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  })),
)(RichEditItem);
