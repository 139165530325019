import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { getProfile } from '../../../../actions/profiles/profiles';
import 'moment/locale/de';

import globeSVG from '../../../../components/Comments/globe.svg';
import defaultAvatarPNG from '../../../../components/Comments/default-avatar.png';

const UserCard = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profiles);
  useEffect(() => {
    dispatch(getProfile(props.userId));
  }, [dispatch, props.userId]);

  return (
    user.get.loaded && (
      <div
        className={cx('user-card', {
          'with-portrait': user.profile.portrait,
        })}
      >
        <div className="user-card-content">
          <div className="user-image">
            {props.avatar !== null ? (
              <img src={props.avatar} alt="" className="user-card-avatar"></img>
            ) : (
              <img
                src={defaultAvatarPNG}
                alt=""
                className="user-card-avatar"
              ></img>
            )}
            <h3>{user.profile.fullname}</h3>
          </div>
          <div className="user-details">
            {user.profile.description && (
              <p className="user-description">{user.profile.description}</p>
            )}
            {user.profile.home_page && (
              <span>
                <img src={globeSVG} className="globe-svg" alt="globe-svg"></img>
                <a href="/" className="user-website">
                  {user.profile.home_page}
                </a>
              </span>
            )}
            <span className="user-dates">
              {/* Geschrieben: &nbsp;
            <p>{moment().fromNow()}</p>
            &nbsp; Beigetreten: &nbsp;
            <p>{moment().format('LLLL')}</p> */}
            </span>
            {user.profile.searching_for_support && (
              <p>
                <strong>
                  Ich suche Unterstützung/Austausch zu folgenden Themen:
                </strong>
                &nbsp;{user.profile.searching_for_support}
              </p>
            )}
            {user.profile.i_can_help_with && (
              <p>
                <strong>Hierbei kann ich helfen:</strong>
                &nbsp;{user.profile.i_can_help_with}
              </p>
            )}
            {user.profile.stadt && (
              <p>
                <strong>Stadt:</strong>
                &nbsp;{user.profile.stadt}
              </p>
            )}
          </div>
        </div>
      </div>
    )
  );
};
export default UserCard;
