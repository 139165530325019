/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, Grid, Icon, Image, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';
import bmbf from '../../../../../public/logo_BMBF.svg';
import dedake from '../../../../../public/logo_Dekade_Alphabetisierung.svg';
import vhsjubilaeum from '../../../../../public/vhs_Logo-Jubilaeum.png';

/**
 * Footer component class.
 * @function Footer
 * @returns {string} Markup of the component
 */
const Footer = () => (
  <div className="footer-wrapper">
    <Grid
      className="logos"
      textAlign="center"
      verticalAlign="middle"
      padded="vertically"
      columns={4}
      stackable
      container
    >
      <Grid.Column>
        <Fade>
          <Image
            className="vhs-footer-logo"
            src={vhsjubilaeum} // Formerly vhs resource svg
            alt="Deutscher Volkshochschul-Verband"
            inline
          />
        </Fade>
      </Grid.Column>
      <Grid.Column>
        <Fade>
          <Image
            className="alpha-dekade-footer-logo"
            src={dedake}
            alt="AlphaDekade"
            inline
          />
        </Fade>
      </Grid.Column>
      <Grid.Column className="bmbf-logo">
        <Fade>
          <Image
            className="bmbf-footer-logo"
            src={bmbf}
            alt="Bundesministerium für Bildung und Forschung"
            inline
          />
        </Fade>
      </Grid.Column>
      <Grid.Row className="footer-disclaimer">
        <Segment basic>
          <p>
            Das vhs-Ehrenamtsportal wurde gemeinsam mit dem Learning Lab der
            Universität Duisburg-Essen entwickelt.
          </p>
          <p>
            Es handelt sich um ein Projekt der Alphadekade 2016 – 2026 und wird
            gefördert vom Bundesministerium für Bildung und Forschung.
          </p>
        </Segment>
      </Grid.Row>
    </Grid>
    <Segment
      vertical
      stacked
      inverted
      clearing
      className="footer"
      textAlign="center"
    >
      <Container className="content">
        <Segment basic className="links">
          <List horizontal inverted className="mobile hidden">
            <List.Item as={Link} to="/ueber-uns/das-projekt">
              Über uns
            </List.Item>
            <List.Item as={Link} to="/kontakt">
              Kontakt
            </List.Item>
            <List.Item as={Link} to="/impressum">
              Impressum
            </List.Item>
            <List.Item as={Link} to="/nutzungsbedingungen">
              Nutzungsbedingungen
            </List.Item>
            <List.Item as={Link} to="/datenschutz">
              Datenschutz
            </List.Item>
            <List.Item as={Link} to="/ueber-uns/pressematerial">
              Pressematerial
            </List.Item>
          </List>
          <List horizontal inverted className="mobile only">
            <List.Item as={Link} to="/uber-uns">
              Über uns
            </List.Item>
            <List.Item as={Link} to="/kontakt">
              Kontakt
            </List.Item>
            <List.Item as={Link} to="/impressum">
              Impressum
            </List.Item>
          </List>
          <List
            horizontal
            inverted
            style={{ marginTop: '10px' }}
            className="mobile only"
          >
            <List.Item as={Link} to="/nutzungsbedingungen">
              Nutzungsbedingungen
            </List.Item>
            <List.Item as={Link} to="/datenschutz">
              Datenschutz
            </List.Item>
            <List.Item as={Link} to="/pressematerial">
              Pressematerial
            </List.Item>
          </List>
        </Segment>
        <Segment basic className="social">
          <a href="https://www.facebook.com/ehrenamtsportal/">
            <Icon
              name="facebook f"
              circular
              size="large"
              className="fontawesome"
            />
          </a>
          <a href="https://twitter.com/vhs_ehrenamt">
            <Icon
              name="twitter"
              circular
              size="large"
              className="fontawesome"
            />
          </a>
          <a href="https://www.youtube.com/channel/UCICJlOPJR3BjKMq7JJOM4rw">
            <Icon
              name="youtube play"
              circular
              size="large"
              className="fontawesome"
            />
          </a>
        </Segment>
      </Container>
    </Segment>
  </div>
);

export default Footer;
