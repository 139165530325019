/**
 * Homepage sprachmodule elements reducer.
 * @module reducers/homepage/sprachmodule
 */
import { has } from 'lodash';

import { GET_HOMEPAGE_SPRACHMODULE } from '../../constants/ActionTypes';
import { settings } from '~/config';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  content: {
    items: [],
  },
};

/**
 * Sprachmodule for homepage reducer.
 * @function sprachmodule
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function sprachmodule(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_HOMEPAGE_SPRACHMODULE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_HOMEPAGE_SPRACHMODULE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        content: {
          items: has(action, 'result.items')
            ? action.result.items
                .filter((item) => item.show_in_homepage)
                .map((item) => ({
                  ...item,
                  url: item['@id'].replace(settings.apiPath, ''),
                }))
            : [],
        },
      };
    case `${GET_HOMEPAGE_SPRACHMODULE}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
        content: {
          items: [],
        },
      };
    default:
      return state;
  }
}
