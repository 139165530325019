import React, { useEffect, useState } from 'react';
import { Grid, Button, Image } from 'semantic-ui-react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import FacebookProvider, { Page } from 'react-facebook';
import cookie from 'react-cookie';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import quotation from '../../icons/quotation.svg';
import { updateCookieConsent } from '@package/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

const View = (props) => {
  const dispatch = useDispatch();
  const facebookConsent = useSelector((state) => state.cookieConsent.facebook);

  const [resize, setResize] = useState(null);

  useEffect(() => {
    setResize(document?.documentElement?.clientWidth);
  }, [resize]);

  const enableFacebook = () => {
    let expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    cookie.save('confirm_facebook', 1, {
      path: '/',
      expires: expiryDate,
    });
    dispatch(updateCookieConsent({ facebook: true }));
  };
  return (
    <div className="news-feed-root">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={6}>
            <div className="card-container">
              <div
                className={cx('newsfeed-image-container', {
                  noImage: !props.data?.url,
                })}
              >
                <Image
                  className="image"
                  src={`${props.data.url}/@@images/image/teaser`}
                  size="medium"
                  circular
                />
                <Icon
                  className="quotation-deco"
                  size="111px"
                  name={quotation}
                />
              </div>
              <div className="newsfeed-text-container">
                <h2>{props.data.title}</h2>
                <p>{props.data.description}</p>
              </div>
              {props.data.href ? (
                <Link to={flattenToAppURL(props.data.href)}>
                  <Button className="button-text">
                    {props.data?.buttonText}
                  </Button>
                </Link>
              ) : (
                <Link>
                  <Button className="button-text">
                    {props.data?.buttonText}
                  </Button>
                </Link>
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            className={cx('facebook', { accepted: facebookConsent })}
            width={6}
          >
            {' '}
            {facebookConsent ? (
              <div id="facebook-feed">
                <FacebookProvider appId="407721649976019">
                  {resize > 900 ? (
                    <Page
                      href="https://www.facebook.com/ehrenamtsportal"
                      tabs="timeline"
                      adaptContainerWidth={true}
                      height={575}
                      width={500}
                    />
                  ) : resize < 350 ? (
                    <Page
                      href="https://www.facebook.com/ehrenamtsportal"
                      tabs="timeline"
                      adaptContainerWidth={true}
                      height={575}
                      width={275}
                    />
                  ) : (
                    <Page
                      href="https://www.facebook.com/ehrenamtsportal"
                      tabs="timeline"
                      adaptContainerWidth={true}
                      height={575}
                      width={375}
                    />
                  )}
                </FacebookProvider>
              </div>
            ) : (
              <div id="facebook-placeholder">
                <div className="facebook-message">
                  <p>
                    Wichtiger Hinweis: Wenn Sie auf diesen Link gehen, werden
                    Inhalte aus folgendem Kanal angezeigt und Verbindungen zu
                    den jeweiligen Serven aufgebaut.
                  </p>

                  <p>
                    Der DVV e.V. macht sich die durch Links erreichbaren
                    Webseiten Dritter nicht zu eigen und ist für deren Inhalt
                    nicht verantwortlich. Der DVV e.V. hat keinen Einfluss
                    darauf, welche personenbezogenen Daten von Ihnen auf dieser
                    Seite verarbeitet werden. Nähere Informationen können Sie
                    hierzu der Datenschutzerklärung des Anbieters der externen
                    Webseite entnehmen.
                  </p>

                  <p>
                    Inhalte des folgenden Kanals werden angezeigt:
                    <br />
                    <a href="https://www.facebook.com/ehrenamtsportal/">
                      facebook.com/ehrenamtsportal
                    </a>
                    <Button
                      className="branded blue"
                      onClick={() => enableFacebook()}
                    >
                      Inhalte von Facebook laden
                    </Button>
                  </p>
                </div>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default View;
