/**
 * DiscourseSSORedirect container.
 * @module components/theme/DiscourseSSORedirect/DiscourseSSORedirect
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { settings } from '~/config';
import qs from 'query-string';

/**
 * DiscourseSSORedirectComponent class.
 * @class DiscourseSSORedirectComponent
 * @extends Component
 */
export class DiscourseSSORedirectComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.any.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Component did mount
   * @function componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    if (!this.props.token) {
      this.props.history.push({
        pathname: '/login',
        state: { return_url: `/discourse_sso${this.props.location.search}` },
      });
    } else {
      window.location.replace(
        `${settings.apiPath}/discourse_sso${this.props.location.search}`,
      );
    }
  }

  /**
   * Convert query params to string
   * @function stringifyQueryParams
   * @returns {string} query
   */
  stringifyQueryParams() {
    let result = '';
    const querystring = qs.parse(this.props.location.search);
    if (!isEmpty(querystring)) {
      Object.entries(querystring).forEach(([key, value]) => {
        if (result === '') {
          result = `${result}?${key}=${value}`;
        } else {
          result = `${result}&${key}=${value}`;
        }
      });
    }
    return result;
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return <div id="page-redirect" />;
  }
}

export default compose(
  asyncConnect([]),
  injectIntl,
  withRouter,
  connect(
    (state, props) => ({
      token: state.userSession.token,
    }),
    (dispatch) => bindActionCreators({}, dispatch),
  ),
)(DiscourseSSORedirectComponent);
